import axios from "axios";
import ClassNames from "classnames";
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { style } from "typestyle";
import {
  APICountry,
  ColorScheme,
  ContainerQueries,
  FormSubmitState,
  Gender,
  I18n,
  Language,
  NewsletterModuleFieldSettings,
  PostNewsletterRequest,
} from "../types/index.js";
import {
  apiBaseURL,
  getLinkStyleClass,
  getSupportedLanguage,
  keys,
} from "../utils/utils.js";
import CountryCategory from "./CountryCategory.js";
import FormField from "./FormField.js";
import LegalNavLink from "./LegalNavLink.js";

interface FormState {
  gender: Gender | null;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
}

interface Props {
  formId: string;
  trans: I18n;
  languageId: Language;
  siteId: string;
  submitState: FormSubmitState;
  setSubmitState: Dispatch<SetStateAction<FormSubmitState>>;
  isPreview: boolean;
  fieldSettings: NewsletterModuleFieldSettings;
  scheme: ColorScheme;
  showFieldBackgrounds: boolean;
  isWideLayout: boolean;
  queries: ContainerQueries;
}

interface CountriesState {
  important: APICountry[];
  all: APICountry[];
}

const getCountries = async (languageId: Language): Promise<CountriesState> => {
  const { data } = await axios.get<APICountry[]>(
    `https://api.bookingsuedtirol.com/widgets/v6/countries?lang=${getSupportedLanguage(
      languageId,
      ["de", "it", "en", "fr"],
    )}`,
  );

  return {
    important: data
      .filter(({ order }) => order !== null)
      .sort((a, b) => ((a.order ?? 0) < (b.order ?? 0) ? -1 : 1)),
    all: data,
  };
};

const submitForm = async ({
  siteId,
  formState,
  languageId,
}: {
  siteId: string;
  formState: FormState;
  languageId: Language;
}) => {
  const body: PostNewsletterRequest = {
    country: formState.country || null,
    email: formState.email,
    firstName: formState.firstName || null,
    lastName: formState.lastName || null,
    gender: formState.gender || null,
    languageId,
  };

  await axios.post(`${apiBaseURL}/sites/${siteId}/newsletter`, body);
};

const getEnabledFieldsCount = (fieldSettings: NewsletterModuleFieldSettings) =>
  1 + keys(fieldSettings).filter((key) => !!fieldSettings[key][0]).length;

const getFormModifierClasses = (
  queries: ContainerQueries,
  fieldSettings: NewsletterModuleFieldSettings,
  isWideLayout: boolean,
): string | undefined => {
  const limits: { [K in keyof ContainerQueries]: number } = {
    "Query--small": 2,
    "Query--medium": 3,
    "Query--large": 4,
    "Query--xlarge": 5,
    "Query--xxlarge": 5,
  };

  const largestQuery = keys(limits)
    .reverse()
    .find((key) => queries[key]);

  if (!largestQuery) return undefined;

  const maxCount = limits[largestQuery];
  const count = getEnabledFieldsCount(fieldSettings);

  return ClassNames({
    [`NewsletterModule__Form--fraction-width-${maxCount}`]: isWideLayout,
    "NewsletterModule__Form--single-line": isWideLayout && count <= maxCount,
    "NewsletterModule__Form--narrow": !isWideLayout,
    "NewsletterModule__Form--wide": isWideLayout,
  });
};

const NewsletterForm: FunctionComponent<Props> = ({
  formId,
  trans,
  languageId,
  siteId,
  setSubmitState,
  submitState,
  isPreview,
  fieldSettings,
  scheme,
  showFieldBackgrounds,
  isWideLayout,
  queries,
}) => {
  const [countries, setCountries] = useState<CountriesState>({
    important: [],
    all: [],
  });

  const [isCountriesLoadError, setIsCountriesLoadError] = useState(false);

  const [formState, setFormState] = useState<FormState>({
    firstName: "",
    lastName: "",
    country: "",
    email: "",
    gender: null,
  });

  const setField = (key: keyof FormState) => (value: string) =>
    setFormState((prevState) => ({ ...prevState, [key]: value }));

  useEffect(() => {
    getCountries(languageId)
      .then((countries) => setCountries(countries))
      .catch(() => setIsCountriesLoadError(true));
  }, []);

  const colors = showFieldBackgrounds
    ? { background: scheme.secondary.background, text: scheme.secondary.text }
    : undefined;

  return (
    <form
      className={ClassNames(
        "NewsletterModule__Form",
        getFormModifierClasses(queries, fieldSettings, isWideLayout),
      )}
      onSubmit={(e) => {
        e.preventDefault();

        setSubmitState("submitting");
        submitForm({ siteId, formState: formState, languageId })
          .then(() => setSubmitState("submitted"))
          .catch(() => setSubmitState("error"));
      }}
    >
      <div className="NewsletterModule__MainFieldset">
        <FormField
          formId={formId}
          label={trans.formFields.gender}
          name="gender"
          value={formState.gender ?? ""}
          onChange={setField("gender")}
          colors={colors}
          render={(props) => (
            <select {...props}>
              <option value="">
                {trans.formFields.gender}
                {fieldSettings.gender[1] ? " *" : ""}
              </option>
              {keys(trans.genders).map((key) => {
                const gender = key as Gender;
                return (
                  <option value={gender} key={gender}>
                    {trans.genders[gender]}
                  </option>
                );
              })}
            </select>
          )}
          settings={fieldSettings.gender}
        />

        <FormField
          formId={formId}
          label={trans.formFields.firstname}
          name="firstName"
          onChange={setField("firstName")}
          colors={colors}
          render={(props) => <input type="text" {...props} />}
          settings={fieldSettings.firstName}
          value={formState.firstName}
        />

        <FormField
          formId={formId}
          label={trans.formFields.lastname}
          name="lastName"
          onChange={setField("lastName")}
          colors={colors}
          render={(props) => <input type="text" {...props} />}
          settings={fieldSettings.lastName}
          value={formState.lastName}
        />

        <FormField
          formId={formId}
          label={trans.formFields.email}
          name="email"
          onChange={setField("email")}
          colors={colors}
          render={(props) => <input type="email" {...props} />}
          settings="required"
          value={formState.email}
        />

        <FormField
          formId={formId}
          label={trans.formFields.country}
          name="country"
          onChange={setField("country")}
          colors={colors}
          render={(props) => (
            <select {...props}>
              <option value="">
                {trans.formFields.country}
                {fieldSettings.country[1] ? " *" : ""}
              </option>
              <CountryCategory
                label={trans.countries.main}
                countries={countries.important}
              />
              <CountryCategory
                label={trans.countries.all}
                countries={countries.all}
              />
            </select>
          )}
          settings={fieldSettings.country}
          value={formState.country}
        />
      </div>

      {isCountriesLoadError && <p>{trans.countries.error}</p>}

      <p
        className={ClassNames(
          "NewsletterModule__PrivacyMessage",
          getLinkStyleClass(scheme),
        )}
      >
        {trans.newsletterMessages.privacy((children) => (
          <LegalNavLink
            legalNavId="privacy"
            languageId={languageId}
            isPreview={isPreview}
          >
            {children}
          </LegalNavLink>
        ))}
      </p>

      <div className="NewsletterModule__SubmitContainer">
        <button
          className={ClassNames(
            "NewsletterModule__SubmitButton",
            "Button",
            style({
              backgroundColor: scheme.primary.background,
              borderColor: scheme.primary.background,
              color: scheme.primary.text,
            }),
          )}
          disabled={submitState === "submitting"}
          type="submit"
        >
          {trans.register}
        </button>
      </div>
    </form>
  );
};

export default NewsletterForm;
